import React, { useState } from "react";
import KineContext from "./KineContext";

const KineProvider = ({ children }) => {
  const [loggeduser, setLoggedUser] = useState([]);

  const updateUserLogin = (user) => {
    setLoggedUser(user);
  };

  return (
    <KineContext.Provider
      value={{
        loggeduser,
        updateUserLogin
      }}
    >
      {children}
    </KineContext.Provider>
  );
};

export default KineProvider;
