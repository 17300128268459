import API from "./api";

const login = async (user, password) => {
  const headers = {
    "x-cors-api-key": process.env.REACT_APP_KEY_PROXY
  };

  try {
    let payload = {
      user: user,
      password: password
    };
    let req = await API.post(`/api/login`, payload, {
      headers: headers
    });
    return req;
  } catch (err) {
    if (err && err.response && err.response) {
      return err.response;
    } else {
      return err;
    }
  }
};

const logout = async () => {
  const headers = {
    "x-cors-api-key": process.env.REACT_APP_KEY_PROXY
  };

  try {
    let req = await API.post(
      `/api/logout`,
      {},
      {
        headers: headers
      }
    );
    let userData = localStorage.getItem("userData");
    if (userData) {
      localStorage.removeItem("userData");
    }
    return req.data;
  } catch (err) {
    if (err && err.response && err.response.data) {
      return err.response.data;
    } else {
      return err;
    }
  }
};

export { login, logout };
