import axios from "axios";

// Verifica se está rodando em localhost
const isLocal = window.location.host.includes("localhost");

// Cria uma instância de axios com a configuração baseURL
const API = axios.create({
  baseURL: isLocal ? "http://127.0.0.1:8000" : process.env.REACT_APP_BASE_URL,
  headers: {
    "x-cors-api-key": process.env.REACT_APP_KEY_PROXY // Adicione esta chave se necessário
  }
});

API.interceptors.request.use(
  (config) => {
    const user = sessionStorage.getItem("userData");

    if (user) {
      //console.log("user", user);
      try {
        const parsedSession = JSON.parse(user);
        // console.log("parsedSession", parsedSession);
        if (parsedSession && parsedSession.access_token) {
          config.headers.Authorization = `Bearer ${parsedSession.access_token}`;
        }
      } catch (error) {
        //	console.error('Error parsing session:', error)
      }
    }

    // Adiciona o endereço se estiver disponível
    if (user) {
      try {
        const parsedUser = JSON.parse(user);
        if (parsedUser && parsedUser.address) {
          config.headers.address = `${parsedUser.address}`;
        }
      } catch (error) {
        console.error("Error parsing userData:", error);
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default API;
