import { ExitIcon } from "@100mslive/react-icons";
import {
  selectIsConnectedToRoom,
  useHMSActions,
  useHMSStore
} from "@100mslive/react-sdk";
import Button from "./Button";
import React, { useContext, useEffect, useState } from "react";
import Icon from "../assets/icons.svg";
import { useNavigate } from "react-router-dom";
import { Loading } from "./Loading";
import { getUserData } from "../service/profile";
import KineContext from "../context/KineContext";
import { logout } from "../service/login";

function Header({ showModal, account }) {
  const navigate = useNavigate();
  const { updateUserLogin } = useContext(KineContext);
  const [fetch, setFetch] = useState(false);
  const [userData, setUserData] = useState([]);
  const navigateToHome = () => {
    navigate("/");
  };

  const getSessionData = async () => {
    const sessionData = sessionStorage.getItem("kinera__session");
    let req = sessionData ? JSON.parse(sessionData) : null;
    if (req) {
      setFetch(true);
      let getProfile = await getUserData(req.value);
      if (getProfile && getProfile.name) {
        setUserData(getProfile);
        setFetch(false);
        updateUserLogin(getProfile);
      }
    }
  };

  const logoutUser = async () => {
    await logout();
    sessionStorage.removeItem("kinera__session");
    sessionStorage.removeItem("userData");
    updateUserLogin(null);
    setUserData(null);
  };

  useEffect(() => {
    getSessionData();
  }, [account]);

  return (
    <header className="flex items-center header justify-between p-2 drop-shadow-2xl">
      <div className="flex items-center gap-4">
        <img className="logo" src={Icon} alt="logo" width="31px" />
        <Button onClick={navigateToHome} type="primary" value="Home"></Button>
      </div>
      {!userData || !userData.name ? (
        <div className="flex items-center gap-2">
          {!fetch ? (
            <Button type="default" value="Login" onClick={showModal}></Button>
          ) : (
            <Loading />
          )}
        </div>
      ) : (
        <div className="flex items-center gap-4">
          <p className="mono">{userData.name}</p>
          <Button type="default" value="Logout" onClick={logoutUser}></Button>
        </div>
      )}
    </header>
  );
}

export default Header;
